import MenuItem from "./MenuItem";
import { MENU_ITEMS } from "@/lib/const";

export default function Aside() {
  return (
    <div className="md:flex hidden flex-col min-w-36 mt-6 pl-5">
      {MENU_ITEMS.map((params) => (
        <MenuItem key={params.path} {...params} />
      ))}
    </div>
  );
}
