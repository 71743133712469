import Button from "@/components/Button";
import { useAuth } from "@/hooks/useAuth";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import moment from "moment";
import { useState } from "react";

const applicationStatus = {
  IN_PROGRESS: {
    text: "Setting up your environment",
    color: "text-warning",
  },
  DONE: {
    text: "The environment is set up",
    color: "text-success",
  },
};

const getStartedContent = [
  {
    title: "Introduction to the platform",
    content: () => (
      <div className="text-object-primary w-full max-w-[745px] m-auto">
        <div className="font-bold text-xs mb-5">Welcome to Multik!</div>
        <div className="text-xs mb-5 text-text">
          This onboarding video will simplify your journey with Multik.
        </div>
        <div className="text-xs text-text">
          To explore more features and capabilities of the platform, visit our 
          <a
            href="https://exantech.atlassian.net/wiki/spaces/CH/pages/113278977/Getting+started"
            target="_blank"
            className="cursor-pointer inline-flex border-b border-accent-primary items-center text-accent-primary"
          >
            Help Center{" "}
            <svg
              className="ml-[2px]"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 6.5V9.5C9 9.76522 8.89464 10.0196 8.70711 10.2071C8.51957 10.3946 8.26522 10.5 8 10.5H2.5C2.23478 10.5 1.98043 10.3946 1.79289 10.2071C1.60536 10.0196 1.5 9.76522 1.5 9.5V4C1.5 3.73478 1.60536 3.48043 1.79289 3.29289C1.98043 3.10536 2.23478 3 2.5 3H5.5"
                stroke="#3282FF"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.5 1.5H10.5V4.5"
                stroke="#3282FF"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5 7L10.5 1.5"
                stroke="#3282FF"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
            for detailed guides and support.
        </div>
        <div className="flex justify-center py-5">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/wuGic9dXAgs?si=QKVvFY-qyx5rjGUL"
            title="YouTube video player"
            allow="accelerometer; autoplay; picture-in-picture"
          ></iframe>
        </div>
      </div>
    ),
  },
  {
    title: "Build your team",
    content: () => (
      <div className="text-text w-full max-w-[745px] m-auto">
        <div className="text-xs mb-5">
          To start working with the Multik platform, you need to perform the
          following steps:
        </div>
        <ol className="list-decimal list-inside ml-2">
          <li className="text-xs mb-5">
            Add yourself as admin and other users through the Mobile
            Provisioning app. To do this, open the app, log in with your
            account, and go to the "Users" section.
          </li>
          <li className="text-xs mb-5">
            Click the "Add New User" button, enter their email address, and
            select the corresponding access role - full access, user access, or
            read-only. After confirming the new user, they will appear in the
            general list of users.
          </li>
          <li className="text-xs mb-5">
            It's recommended to add yourself first to ensure that you can join
            your workspace. This will facilitate further setup and usage of the
            platform.
          </li>
        </ol>
        <a
          href="https://exantech.atlassian.net/wiki/spaces/CH/pages/297959425/Adding+Users"
          target="_blank"
          className="ml-2 mb-5 text-xs cursor-pointer inline-flex border-b border-accent-primary items-center text-accent-primary"
        >
          Learn more{" "}
          <svg
            className="ml-[2px]"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 6.5V9.5C9 9.76522 8.89464 10.0196 8.70711 10.2071C8.51957 10.3946 8.26522 10.5 8 10.5H2.5C2.23478 10.5 1.98043 10.3946 1.79289 10.2071C1.60536 10.0196 1.5 9.76522 1.5 9.5V4C1.5 3.73478 1.60536 3.48043 1.79289 3.29289C1.98043 3.10536 2.23478 3 2.5 3H5.5"
              stroke="#3282FF"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.5 1.5H10.5V4.5"
              stroke="#3282FF"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5 7L10.5 1.5"
              stroke="#3282FF"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </a>
      </div>
    ),
  },
  {
    title: "Add exchange accounts",
    content: () => (
      <div className="text-text w-full max-w-[745px] m-auto">
        <div className="text-xs mb-5">
          To manage your exchange accounts through the Multik interface, you
          need to add exchange API keys using the Provisioning App.
        </div>
        <ol className="list-decimal list-inside ml-2">
          <li className="text-xs mb-5">
            Start by opening the app and navigating to the "Accounts" section.
          </li>
          <li className="text-xs mb-5">
            Tap "Add new account", select your exchange, and enter the required
            account details, including the API keys.
          </li>
        </ol>

        <a
          href="https://exantech.atlassian.net/wiki/spaces/CH/pages/297861122/Adding+an+Exchange+Accounts"
          target="_blank"
          className="ml-2 mb-5 text-xs cursor-pointer inline-flex border-b border-accent-primary items-center text-accent-primary"
        >
          Learn more{" "}
          <svg
            className="ml-[2px]"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 6.5V9.5C9 9.76522 8.89464 10.0196 8.70711 10.2071C8.51957 10.3946 8.26522 10.5 8 10.5H2.5C2.23478 10.5 1.98043 10.3946 1.79289 10.2071C1.60536 10.0196 1.5 9.76522 1.5 9.5V4C1.5 3.73478 1.60536 3.48043 1.79289 3.29289C1.98043 3.10536 2.23478 3 2.5 3H5.5"
              stroke="#3282FF"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.5 1.5H10.5V4.5"
              stroke="#3282FF"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5 7L10.5 1.5"
              stroke="#3282FF"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </a>
      </div>
    ),
  },
  {
    title: "Set up the policy engine",
    content: () => (
      <div className="text-text w-full max-w-[745px] m-auto">
        <div className="text-xs mb-5">
          To configure user limits, voting power, account limits, and tags in
          the Policy Engine, follow these steps available to admins with Full
          Access rights. This setup helps manage transaction capabilities and ensures compliance with financial regulations.
        </div>
        <ul className="list-disc list-inside ml-2">
          <li className="text-xs mb-5">
            <span className="font-bold">User Limits</span>: Define maximum
            amounts for single transactions and total transfers within specified
            periods (e.g., daily or weekly). Adjust vote weights to influence
            transaction approval processes.
          </li>
          <li className="text-xs mb-5">
            <span className="font-bold">Account Limits</span>: Set predefined
            thresholds for fund flows in and out of accounts. These include
            general limits in USD and specific currency limits to maintain
            financial control and compliance.
          </li>
          <li className="text-xs mb-5">
            <span className="font-bold">Tags</span>: Use tags to categorize and
            manage transactions or accounts, enhancing organization and tracking
            within the platform.
          </li>
        </ul>
        <div className="text-xs mb-5">
          For detailed instructions, please refer to the full guide.
        </div>
        <a
          href="https://exantech.atlassian.net/wiki/spaces/CH/folder/356089868"
          target="_blank"
          className="mb-5 text-xs cursor-pointer inline-flex border-b border-accent-primary items-center text-accent-primary"
        >
          Learn more{" "}
          <svg
            className="ml-[2px]"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 6.5V9.5C9 9.76522 8.89464 10.0196 8.70711 10.2071C8.51957 10.3946 8.26522 10.5 8 10.5H2.5C2.23478 10.5 1.98043 10.3946 1.79289 10.2071C1.60536 10.0196 1.5 9.76522 1.5 9.5V4C1.5 3.73478 1.60536 3.48043 1.79289 3.29289C1.98043 3.10536 2.23478 3 2.5 3H5.5"
              stroke="#3282FF"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.5 1.5H10.5V4.5"
              stroke="#3282FF"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5 7L10.5 1.5"
              stroke="#3282FF"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </a>{" "}
         
      </div>
    ),
  },
  {
    title: "Sent first transfer",
    content: () => (
      <div className="text-text w-full max-w-[745px] m-auto">
        <div className="text-xs mb-5">
          Transactions are the primary way to move assets between wallets or
          accounts on the platform. They represent a request to transfer funds
          from one party to another. The process involves proposing a
          transaction, waiting for approval, and then executing the transfer
          once approved.
        </div>
        <div className="text-xs mb-5">To send funds on Multik:</div>
        <ol className="list-decimal list-inside ml-2">
          <li className="text-xs mb-5">
            Navigate to the Send Funds screen after logging into your account.
          </li>
          <li className="text-xs mb-5">
            Select the asset, wallet, and recipient wallet you want to send to.
          </li>
          <li className="text-xs mb-5">
            Enter the amount and click "Propose Transaction". Confirm
            transaction with your HOTP code from your 2FA app.
          </li>
          <li className="text-xs mb-5">
            Wait for the transaction to be approved by other users (usually
            requires at least 3 votes). For some exchanges, you may need to
            enter an additional 2FA code or confirm via email.
          </li>
          <li className="text-xs mb-5">
            Once approved, the transaction will be executed and appear in your
            history with its status.
          </li>
        </ol>
        <a
          href="https://exantech.atlassian.net/wiki/spaces/CH/pages/342687745/Transaction+Lifecycle"
          target="_blank"
          className="mb-5 text-xs cursor-pointer inline-flex border-b border-accent-primary items-center text-accent-primary"
        >
          Learn more{" "}
          <svg
            className="ml-[2px]"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 6.5V9.5C9 9.76522 8.89464 10.0196 8.70711 10.2071C8.51957 10.3946 8.26522 10.5 8 10.5H2.5C2.23478 10.5 1.98043 10.3946 1.79289 10.2071C1.60536 10.0196 1.5 9.76522 1.5 9.5V4C1.5 3.73478 1.60536 3.48043 1.79289 3.29289C1.98043 3.10536 2.23478 3 2.5 3H5.5"
              stroke="#3282FF"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.5 1.5H10.5V4.5"
              stroke="#3282FF"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5 7L10.5 1.5"
              stroke="#3282FF"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </a>{" "}
      </div>
    ),
  },
];

export default function Dashboard() {
  const [openedStep, setOpenedStep] = useState<number | null>(0);
  const { user } = useAuth();
  // @ts-ignore
  const status = applicationStatus[user?.provision_setup_status];
  return (
    <div className="w-full">
      <div className="text-[14px] mb-16 min-w-[320px]">
        <div className="md:hidden block text-[16px] font-bold mb-5 text-object-primary pl-4">Dashboard</div>
        <div className="text-object-primary text-[18px] font-bold mb-5">
          Summary
        </div>
        <div className="mb-4 flex">
          <div className="w-[100px]">Workspace: </div>
          <div className="text-accent-primary font-bold">{user?.domain}</div>
        </div>
        <div className="mb-4 flex">
          <div className="w-[100px]">SGX domain: </div>
          <div className="font-bold text-object-primary">
            {user?.provision_domain}
          </div>
        </div>
        <div className="mb-4 flex">
          <div className="w-[100px]">IP server: </div>
          <div className="text-object-primary">{user?.server_ip}</div>
        </div>
        <div className="mb-4 flex">
          <div className="w-[100px]">Status: </div>
          <div className={`font-bold ${status?.color}`}>{status?.text}</div>
        </div>
        <div className="mb-4 flex">
          <div className="w-[100px]">Current plan: </div>
          <div className="font-bold text-object-primary">
            <span className="capitalize">{user?.plan.toLowerCase()}</span>{" "}
            <span className="font-normal">
              (paid until {moment(user?.paid_until).format("MMMM DD, YYYY")})
            </span>
          </div>
        </div>
      </div>
      <div className="pr-5 pb-16">
        <div className="text-object-primary text-[18px] font-bold mb-5">
          Get started
        </div>
        <div>
          {getStartedContent.map(({ title, content: Content }, idx) => {
            const isOpened = openedStep === idx;
            const setOpened = (value: boolean) =>
              setOpenedStep(value ? idx : null);
            return (
              <div className="mb-3" key={title}>
                <div
                  className={`px-6 py-4 bg-border ${
                    isOpened ? "rounded-t-md" : "rounded-md"
                  }`}
                  onClick={() => setOpened(!isOpened)}
                >
                  <div className="text-[12px] font-bold text-object-primary">
                    {title}
                  </div>
                </div>
                <AnimatePresence mode="sync">
                  {isOpened && (
                    <motion.div
                      className="bg-highlight"
                      key="content"
                      initial="collapsed"
                      animate="open"
                      exit="collapsed"
                      variants={{
                        open: { opacity: 1, maxHeight: "1000px" },
                        collapsed: { opacity: 0, maxHeight: 0 },
                      }}
                      transition={{
                        duration: 0.3,
                      }}
                    >
                      <div className="px-6 py-4 rounded-b-md">
                        <Content />
                        <div className="flex justify-between">
                          {idx > 0 ? (
                            <div>
                              <Button
                                onClick={() => setOpenedStep(idx - 1)}
                                type="outlined"
                                text="Back"
                                before={
                                  <svg
                                    width="5"
                                    height="9"
                                    viewBox="0 0 5 9"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M4 1.5L1 4.5L4 7.5"
                                      stroke="white"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                }
                              />
                            </div>
                          ) : (
                            <div></div>
                          )}
                          <div>
                            {idx === getStartedContent.length - 1 ? (
                              <a
                                role="button"
                                href={`https://${user?.domain}`}
                                target="_blank"
                              >
                                <Button
                                  type="primary"
                                  className="text-object-primary"
                                  text={"Go to multik"}
                                  after={
                                    <svg
                                      width="5"
                                      height="9"
                                      viewBox="0 0 6 10"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M1 9L5 5L1 1"
                                        stroke="white"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  }
                                />
                              </a>
                            ) : (
                              <Button
                                type="primary"
                                className="text-object-primary"
                                text={"Next"}
                                onClick={() => setOpenedStep(idx + 1)}
                                after={
                                  <svg
                                    width="5"
                                    height="9"
                                    viewBox="0 0 6 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1 9L5 5L1 1"
                                      stroke="white"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
