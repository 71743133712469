import Button from "../Button";
import { useOnboarding } from "@/hooks/useOnboarding";

const SuccessTick = () => {
  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 1L4.125 8L1 4.81818"
        stroke="#4CAF50"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const FailedTick = () => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_179_228)">
        <path
          d="M8 0L0 8"
          stroke="#E64022"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0 0L8 8"
          stroke="#E64022"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_179_228">
          <rect width="8" height="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const tableRows = [
  {
    title: "Intel SGX Enclave ©",
    advanced: <SuccessTick />,
    enterprice: <SuccessTick />,
  },
  {
    title: "Exchange accounts",
    advanced: "5 accounts",
    enterprice: "Unlimited",
  },
  { title: "User seats", advanced: "3 users", enterprice: "Unlimited" },
  {
    title: "Account pools",
    advanced: <FailedTick />,
    enterprice: "Unlimited",
  },
  {
    title: "Account thresholds",
    advanced: <FailedTick />,
    enterprice: <SuccessTick />,
  },
  {
    title: "User spending limits",
    advanced: <FailedTick />,
    enterprice: <SuccessTick />,
  },
  {
    title: "Deployment options",
    advanced: "Cloud",
    enterprice: "Cloud or On-premise",
  },
];

export default function ChoosePlan() {
  const { choosePlan } = useOnboarding();

  return (
    <>
      <div className="hidden md:block">
        <div className="flex justify-between mb-5">
          <div className="w-[257px] text-[14px] font-bold">
            Choose your plan:
          </div>
          <div className="w-[257px] flex flex-col items-center">
            <div className="text-[14px] font-bold mb-2">Advanced</div>
            <div className="mb-1 text-[14px] font-bold">
              <span className="text-error line-through text-[20px]">$250</span>{" "}
              <span className="text-[20px]">$100</span>/month{" "}
            </div>
            <div className="w-fit mt-3">
              <Button
                text="Choose plan"
                onClick={() => choosePlan({ plan: "ADVANCED" })}
                type="primary"
              />
            </div>
          </div>
          <div className="w-[257px] flex flex-col items-center">
            <div className="text-[14px] font-bold mb-[14px]">Enterprise</div>
            <div className="mb-[27px] text-[14px] font-bold">
              Contact us at sales@multik.io
            </div>
            <div>
              <a
                href="https://rw581qtdr3f.typeform.com/to/fiAdPvIk"
                target="_blank"
                className="text-[12px] cursor-pointer font-bold text-accent-primary"
              >
                Fill out the interest form
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          {tableRows.map(({ title, advanced, enterprice }, index) => (
            <div
              key={title}
              className={`text-[14px] flex justify-between items-center px-4 py-3 ${
                index % 2 === 0 ? "bg-base" : "bg-transparent"
              }`}
            >
              <div className="w-[257px]">{title}</div>
              <div className="w-[257px] text-center flex justify-center">
                {advanced}
              </div>
              <div className="w-[257px] text-center flex justify-center">
                {enterprice}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="block md:hidden mt-5">
        <div className="flex flex-col pb-5">
          <div className="flex flex-col items-center">
            <div className="text-[14px] font-bold text-left w-[85%]">
              Choose a plan
            </div>
            <div className="w-[85%] bg-base rounded-lg text-center mt-4 py-5">
              <div className="text-[16px] font-bold mb-4">Advanced</div>
              <div className="">
                <span className="line-through text-error text-[20px] font-bold">
                  $250
                </span>{" "}
                <span className="text-[20px] font-bold">$100</span>/month{" "}
              </div>
              <div className="flex justify-center">
                <Button
                  text="Choose plan"
                  before={<div></div>}
                  after={<div></div>}
                  className="py-2 my-4 px-4 text-[16px] w-[163px] "
                  onClick={() => choosePlan({ plan: "ADVANCED" })}
                  type="primary"
                />
              </div>
              <div className="font-accent text-[14px] text-object-secondary">
                <div className="mb-2">Intel SGX Enclave ©</div>
                <div className="mb-2">5 exchange accounts</div>
                <div className="">3 user seats</div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center">
            <div className="w-[85%] bg-base rounded-lg text-center mt-4 py-5">
              <div className="text-[16px] font-bold mb-4">Enterprise</div>
              <div className="text-[14px]">Contact us at sales@multik.io</div>
              <div className="my-4">
                <a
                  href="https://rw581qtdr3f.typeform.com/to/fiAdPvIk"
                  target="_blank"
                  className="text-[16px] cursor-pointer font-bold text-accent-primary"
                >
                  Fill out the interest form
                </a>
              </div>
              <div className="font-accent text-[14px] text-object-secondary">
                <div className="mb-2">Intel SGX Enclave ©</div>
                <div className="mb-2">Unlimited exchange accounts</div>
                <div className="mb-2">Unlimited user seats</div>
                <div className="mb-2">Unlimited account pools</div>
                <div className="mb-2">Account thresholds</div>
                <div className="mb-2">User spending limits</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
