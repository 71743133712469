import { useEffect, useRef, useState } from "react";
import Button from "../Button";
import CodeInput from "../CodeInput";
import DidntReceiveModal from "./DidntReceiveModal";
import { useAuth } from "@/hooks/useAuth";
import { useInput } from "@/hooks/useInput";
import { useOnboarding } from "@/hooks/useOnboarding";
import { parseError } from "@/lib/error";

export default function VerifyEmail() {
  const { user } = useAuth();
  const { resendCode, confirmEmail } = useOnboarding();
  const code = useInput("");
  const email = user.email;
  const [countdown, setCountdown] = useState(59);
  const ref = useRef(-1);
  const intervalRef = useRef(-1);

  const onClick = async () => {
    try {
      await confirmEmail({ code: code.value });
    } catch (error) {
      code.setError(parseError(error));
    }
  };

  useEffect(() => {
    window.clearTimeout(ref.current);
    ref.current = window.setTimeout(async () => {
      if (code.value.length === 6 && !code.error) {
        try {
          await confirmEmail({ code: code.value });
        } catch (error) {
          code.setError(parseError(error));
        }
      }
    }, 900);
  }, [code]);

  const onResend = async () => {
    if (countdown !== 0) return;
    try {
      await resendCode();
      setCountdown(59);
      const interval = window.setInterval(() => {
        localStorage.setItem("codeConfirmStartTime", new Date().toISOString());
        setCountdown((value) => {
          if (value <= 1) {
            clearInterval(interval);
          }
          return value - 1 >= 0 ? value - 1 : 0;
        });
      }, 1000);
      intervalRef.current = interval;
    } catch (error) {
      code.setError(parseError(error));
    }
  };

  useEffect(() => {
    const localStorageTimer = localStorage.getItem("codeConfirmStartTime");
    if (localStorageTimer) {
      const codeConfirmStartTime = new Date(localStorageTimer);
      const currentTime = new Date();
      const differenceInSeconds =
        (currentTime.getTime() - codeConfirmStartTime.getTime()) / 1000;
      if (Math.floor(differenceInSeconds) <= 59) {
        setCountdown(59 - Math.floor(differenceInSeconds));
      } else {
        setCountdown(0);
      }
    }

    const interval = window.setInterval(async () => {
      try {
        const localStorageTimer = localStorage.getItem("codeConfirmStartTime");
        if (!localStorageTimer) {
          await resendCode();
          localStorage.setItem(
            "codeConfirmStartTime",
            new Date().toISOString()
          );
        }
        setCountdown((value) => {
          if (value <= 1) {
            clearInterval(interval);
          }
          return value - 1 >= 0 ? value - 1 : 0;
        });
      } catch (error) {
        window.clearInterval(intervalRef.current);
        const interval = window.setInterval(() => {
          setCountdown((value) => {
            if (value <= 1) {
              clearInterval(interval);
            }
            return value - 1 >= 0 ? value - 1 : 0;
          });
        }, 1000);
        code.setError(parseError(error));
      }
    }, 1000);
    intervalRef.current = interval;

    return () => {
      window.clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <>
      <div className="hidden md:block">
        <div className="text-[12px] text-text mb-2">
          Please enter the 6-digit code that we’ve sent to{" "}
          <span className="font-bold">{email}</span>. This code is valid for the
          next 30 minutes.
        </div>
        <div
          onClick={code.reset}
          className={`${
            code.value
              ? "opacity-1 pointer-events-auto"
              : "opacity-0 pointer-events-none"
          } cursor-pointer text-right w-[280px] text-accent-primary uppercase text-[10px] font-bold mb-1`}
        >
          Reset
        </div>
        <div className="flex items-center relative">
          <CodeInput
            value={code.value}
            error={code.error}
            onChange={code.onChange}
          />
          <div className="text-[12px] text-text ml-4" onClick={onResend}>
            00:{countdown > 9 ? countdown : "0" + countdown}{" "}
            <span
              className={`font-bold ${
                countdown === 0
                  ? "text-accent-primary"
                  : "text-object-secondary"
              } mx-1 cursor-pointer`}
            >
              Resend
            </span>
          </div>
        </div>

        <div className="text-error text-[12px] mt-2">{code.error}</div>
        <div className="flex justify-between items-center mt-5">
          <DidntReceiveModal />
          <div>
            <Button
              onClick={onClick}
              type={code?.value?.length === 6 ? "primary" : "disabled"}
              text="Submit"
              after={
                <svg
                  width="5"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 9L5 5L1 1"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
            />
          </div>
        </div>
      </div>
      <div className="block md:hidden py-4 px-2">
        <div className="text-object-primary text-[16px] font-bold mb-2">
          Verify your email
        </div>
        <div className="text-[14px] text-text mb-2">
          A 6-digit code has been sent to{" "}
          <span className="font-bold">{email}</span>.<br />
          <br /> Please enter it within the next 30 minutes.
        </div>
        <div
          onClick={code.reset}
          className={`${
            code.value
              ? "opacity-1 pointer-events-auto"
              : "opacity-0 pointer-events-none"
          } cursor-pointer text-right w-[280px] text-accent-primary uppercase text-[10px] font-bold mb-1`}
        >
          Reset
        </div>
        <div className="flex items-center relative">
          <CodeInput
            value={code.value}
            error={code.error}
            onChange={code.onChange}
          />
        </div>

        <div className="text-error text-[14px] mt-2">{code.error}</div>
        <div className="mt-6">
          <Button
            onClick={onClick}
            className="text-[16px] py-2 px-4"
            type={code?.value?.length === 6 ? "primary" : "disabled"}
            text="Submit"
            before={<div></div>}
            after={
              <svg
                width="7"
                height="13"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 9L5 5L1 1"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
          />
        </div>
        <div className="text-[14px] text-text mt-4" onClick={onResend}>
          00:{countdown > 9 ? countdown : "0" + countdown}{" "}
          <span
            className={`font-bold ${
              countdown === 0 ? "text-accent-primary" : "text-object-secondary"
            } mx-1 cursor-pointer`}
          >
            Resend
          </span>
        </div>
        <div className="flex justify-between items-center mt-4">
          <DidntReceiveModal isMobile={true} />
        </div>
      </div>
    </>
  );
}
