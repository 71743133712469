import { useState } from "react";

export default function useToggle(initialValue: boolean): [boolean, (forceValue?: boolean) => void] {
  const [value, setValue] = useState(initialValue);

  const toggleValue = (forceValue?: any) => {
    if (forceValue !== undefined) {
      setValue(forceValue);
    } else {
      setValue(!value);
    }
  };

  return [value, toggleValue];
}
