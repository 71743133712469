import useToggle from "@/hooks/useToggle";
import Profile from "../Header/Profile";
import { MENU_ITEMS } from "@/lib/const";
import MenuItem from "../Aside/MenuItem";
import { useAuth } from "@/hooks/useAuth";
import { motion, AnimatePresence } from "framer-motion";

export default function MobileAside() {
  const [opened, toggleOpened] = useToggle(false);
  const { signOut } = useAuth();
  const isOnboarded = localStorage?.getItem("isOnboarded") !== "false";

  const open = () => {
    toggleOpened(true);
  };

  const close = () => {
    toggleOpened(false);
  };

  return (
    <div>
      <div
        className="flex flex-col h-[14px] justify-between items-center cursor-pointer"
        onClick={open}
      >
        <div className="w-[18px] h-[2px] bg-white rounded-full"></div>
        <div className="w-[18px] h-[2px] bg-white rounded-full"></div>
        <div className="w-[18px] h-[2px] bg-white rounded-full"></div>
      </div>
      <AnimatePresence>
        {opened && (
          <motion.div
            className="z-50 absolute top-0 h-full bg-base w-[320px] pr-[40px] overflow-hidden"
            initial={{ x: 0 }}
            animate={{ x: -240 }}
            exit={{ x: 240 }}
          >
            {/* <div className="absolute h-full bg-base"> */}
            <div className="flex items-center justify-between p-4">
              <svg
                onClick={close}
                className="cursor-pointer"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.5 7.5L7.5 22.5"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.5 7.5L22.5 22.5"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <Profile openable={false} />
            </div>
            <div className="text-right pr-2">
              {isOnboarded && (
                <div>
                  {MENU_ITEMS.map((params) => (
                    <MenuItem isMobile key={params.path} {...params} close={close} />
                  ))}
                </div>
              )}
              <div className="mt-10 text-[14px] font-bold flex justify-end items-center mb-2 px-[10px] py-2 capitalize rounded-md text-object-secondary">
                <div className="mr-2 cursor-pointer" onClick={signOut}>
                  Logout
                </div>
                <div className="w-4">
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 600 600"
                    className="fill-object-secondary pt-1"
                  >
                    <g>
                      <path
                        d="M332.8,153.6v-51.2c0-42.4-34.4-76.8-76.8-76.8H76.8C34.4,25.6,0,60,0,102.4v307.2c0,42.4,34.4,76.8,76.8,76.8H256
		c42.4,0,76.8-34.4,76.8-76.8v-51.2c0-14.1-11.5-25.6-25.6-25.6s-25.6,11.5-25.6,25.6v51.2c0,14.1-11.5,25.6-25.6,25.6H76.8
		c-14.1,0-25.6-11.5-25.6-25.6V102.4c0-14.1,11.5-25.6,25.6-25.6H256c14.1,0,25.6,11.5,25.6,25.6v51.2c0,14.1,11.5,25.6,25.6,25.6
		S332.8,167.7,332.8,153.6z"
                      />
                      <path
                        d="M128,281.6h358.4c10.4,0,19.7-6.2,23.7-15.8c4-9.6,1.8-20.6-5.5-27.9l-76.8-76.8c-10-10-26.2-10-36.2,0
		c-10,10-10,26.2,0,36.2l33.1,33.1l-296.6,0c-14.1,0-25.6,11.5-25.6,25.6C102.4,270.1,113.9,281.6,128,281.6L128,281.6z
		 M427.7,350.9l76.8-76.8c10-10,10-26.2,0-36.2s-26.2-10-36.2,0l-76.8,76.8c-10,10-10,26.2,0,36.2
		C401.5,360.9,417.7,360.9,427.7,350.9z"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            {/* </div> */}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
