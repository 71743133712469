import ArrowMenu from "@/assets/icons/arrow-menu.svg";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useAuth } from "@/hooks/useAuth";

const AccountIcon = () => {
  return (
    <svg
      className="stroke-accent-secondary"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 13.125V11.875C12.5 11.212 12.2366 10.5761 11.7678 10.1072C11.2989 9.63839 10.663 9.375 10 9.375H5C4.33696 9.375 3.70107 9.63839 3.23223 10.1072C2.76339 10.5761 2.5 11.212 2.5 11.875V13.125"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 6.875C8.88071 6.875 10 5.75571 10 4.375C10 2.99429 8.88071 1.875 7.5 1.875C6.11929 1.875 5 2.99429 5 4.375C5 5.75571 6.11929 6.875 7.5 6.875Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default function Profile({ openable = true }: { openable?: boolean }) {
  const { signOut, user } = useAuth();
  const localStorageEmail = localStorage.getItem("email");
  const email = user?.email || localStorageEmail;
  const [isOpened, setOpened] = useState(false);
  const toggleOpened = () => {
    setOpened(!isOpened);
  };
  const onLogout = () => {
    signOut();
  };
  return (
    <div className="cursor-pointer relative" onClick={openable ? toggleOpened : undefined}>
      <div className="text-object-primary flex text-[14px] items-center">
        <div>
          <AccountIcon />
        </div>
        <div className="font-bold mx-2">{email}</div>
        {openable && <img
          src={ArrowMenu}
          alt=""
          className="mt-[3px]"
          style={{ transform: isOpened ? "rotate(180deg)" : "none" }}
        />}
      </div>
      <AnimatePresence initial={false}>
        {isOpened && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, y: 15 },
              collapsed: { opacity: 0, y: 5 },
            }}
            transition={{
              duration: 0.3,
            }}
          >
            <div
              onClick={onLogout}
              className="text-[14px] absolute bg-highlight rounded-md py-2 px-4 right-0"
            >
              <div>Logout</div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
