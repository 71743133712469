import * as Dialog from "@radix-ui/react-dialog";
import { AnimatePresence, motion } from "framer-motion";
import { createPortal } from "react-dom";
import Button from "./Button";
import { useState } from "react";
import { useAuth } from "@/hooks/useAuth";

export default function ChangePasswordModal() {
  const [open, setOpen] = useState(false);
  const [isResetted, setIsResetted] = useState(false);
  const { resetPassword, user } = useAuth();

  const onReset = async () => {
    await resetPassword({ email: user.email });
    setIsResetted(true);
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger>
        <Button
          className="px-4 py-2 text-object-primary"
          type="primary"
          text="Change password"
        />
        {createPortal(
          <AnimatePresence mode="popLayout">
            {open && (
              <motion.div
                className="overflow-hidden w-full h-full absolute top-0 left-0"
                exit={{
                  backdropFilter: "blur(5px) opacity(0)",
                }}
                initial={{
                  backdropFilter: "blur(5px) opacity(0)",
                }}
                animate={{
                  backdropFilter: "blur(5px) opacity(1)",
                }}
              ></motion.div>
            )}
          </AnimatePresence>,
          document.getElementById("blur") as HTMLElement
        )}
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className="fixed bottom-4 left-[50%] w-[calc(100%-36px)] md:w-auto md:top-[50%] md:left-[50%] -translate-x-[50%] md:-translate-y-[50%]">
          <div className="p-5 w-full md:max-w-[370px] bg-base rounded-md border border-border text-text">
            <div>
              <div className="flex justify-between items-center mb-5">
                <div className="text-[20px] font-bold">Reset password</div>
                <div className="cursor-pointer" onClick={() => setOpen(!open)}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 5L5 15"
                      stroke="#475673"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5 5L15 15"
                      stroke="#475673"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              {isResetted ? (
                <div className="flex flex-col">
                  <div className="text-[14px] mb-5">
                    A link to reset your password has been sent to your email.
                  </div>
                  <div className="text-[14px]">
                    Please check your inbox and follow the instructions to reset
                    your password.
                  </div>
                  <div className="w-full h-[1px] bg-border my-5"></div>
                  <div className="flex justify-between items-center">
                    <div></div>
                    <div>
                      <Button
                        type="primary"
                        className="text-object-primary"
                        text="Close"
                        onClick={() => setOpen(false)}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col">
                  <div className="text-[14px]">
                    Are you sure you want to reset your password? If you
                    proceed, a password reset link will be sent to your email.
                  </div>
                  <div className="w-full h-[1px] bg-border my-5"></div>
                  <div className="flex justify-between items-center">
                    <div
                      className="uppercase text-[14px] font-bold text-accent-primary cursor-pointer"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </div>
                    <div>
                      <Button
                        type="primary"
                        className="text-object-primary"
                        text="Confirm"
                        onClick={onReset}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
