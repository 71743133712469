import { QRCodeSVG } from "qrcode.react";
import useWindowDimensions from "../hooks/useWindowDimensions";
export default function DownloadQR() {
  const { width } = useWindowDimensions();
  console.log(width);
  return (
    <QRCodeSVG
      value={
        "https://play.google.com/store/apps/details?id=com.exantech.custody"
      }
      size={width < 768 ? 120 : 68}
    />
  );
}
