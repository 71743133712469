import { Link, useLocation } from "react-router-dom";

interface MenuItemProps {
  path: string;
  icon: ({ active }: { active: boolean }) => JSX.Element;
  isMobile?: boolean;
  close?: () => void;
}

export default function MenuItem({ path, icon: Icon, isMobile = false, close }: MenuItemProps) {
  const location = useLocation();
  const isActive = location.pathname === `/${path}`;

  return (
    <div
      className={`text-[14px] font-bold flex items-center mb-2 px-3 py-2 capitalize rounded-md ${
        isActive ? "text-object-primary bg-highlight" : "text-object-secondary"
      } ${isMobile ? "justify-end" : ""}`}
    >
      {!isMobile && (
        <div className="w-4">
          <Icon active={isActive} />
        </div>
      )}
      <Link onClick={isMobile ? close : undefined} className="px-2" to={`/${path}`}>
        {path}
      </Link>
      {isMobile && (
        <div className="w-4">
          <Icon active={isActive} />
        </div>
      )}
    </div>
  );
}
