import { useInput } from "@/hooks/useInput";
import Button from "../Button";
import DomainInput from "../DomainInput";
import { useOnboarding } from "@/hooks/useOnboarding";
import { parseError } from "@/lib/error";

export default function ChooseDomain() {
  const { chooseDomain } = useOnboarding();
  const domain = useInput("");

  const onClick = async () => {
    try {
      if (domain.value.length < 5) {
        domain.setError("The domain must be at least 5 characters long.");
        return;
      }
      if (!/^[a-zA-Z0-9-]+$/.test(domain.value)) {
        domain.setError(
          "Only Latin characters, numbers, and hyphens are allowed."
        );
        return;
      }
      await chooseDomain({ domain: `${domain.value}.multik.io` });
    } catch (error) {
      domain.setError(parseError(error));
    }
  };

  return (
    <>
      <div className="-mb-5 hidden md:block">
        <div className="text-text text-[12px]">
          Choose a domain from the&nbsp;
          <span className="underline">domain.multik.io</span> zone to use for
          your workspace.
          <ul className="list-disc list-outside p-1 pl-4 mt-3">
            <li className="">The domain must be at least 5 characters long.</li>
            <li className="">Only Latin characters are allowed.</li>
          </ul>
        </div>
        <div className="my-5 flex justify-between items-center">
          <DomainInput domain={domain} />
          <Button
            onClick={onClick}
            text="confirm"
            className="h-[38px]"
            type={domain.value.length ? "primary" : "disabled"}
            after={
              <svg
                width="5"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 9L5 5L1 1"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
          />
        </div>
      </div>
      <div className="-mb-5 block md:hidden">
        <div className="text-object-primary text-[16px] font-bold my-4">
          Choose domain
        </div>
        <div className="text-text text-[12px]">
          Choose a domain from the&nbsp;
          <span className="underline">domain.multik.io</span> zone to use for
          your workspace.
          <ul className="list-disc list-outside p-1 pl-4 mt-3">
            <li className="">The domain must be at least 5 characters long.</li>
            <li className="">Only Latin characters are allowed.</li>
          </ul>
        </div>
        <div className="my-6">
          <DomainInput domain={domain} />
        </div>
        <Button
          onClick={onClick}
          text="confirm"
          className="h-[38px] px-2 text-[16px]"
          type={domain.value.length ? "primary" : "disabled"}
          before={<div></div>}
          after={
            <svg
              width="7"
              height="13"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 9L5 5L1 1"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
        />
      </div>
    </>
  );
}
