import { useState } from "react";
import Button from "../Button";
import Input from "../Input";
import { Link } from "react-router-dom";
import ViewInstructionsModal from "./ViewInstructionsModal";
import { useAuth } from "@/hooks/useAuth";
import { useInput } from "@/hooks/useInput";
import { useOnboarding } from "@/hooks/useOnboarding";
import { parseError } from "@/lib/error";

const applicationStatus = {
  IN_PROGRESS: {
    text: "Setting up your environment",
    color: "text-warning",
  },
  DONE: {
    text: "The environment is set up",
    color: "text-success",
  },
};

export default function GenerateKeys() {
  const { user } = useAuth();
  const { setProvisionPublicKey } = useOnboarding();
  const [createdAccout, setCreatedAccount] = useState(false);
  const pubKey = useInput();
  // @ts-ignore
  const status = applicationStatus[user?.provision_setup_status];

  const onClick = async () => {
    try {
      await setProvisionPublicKey({ pubKey: pubKey.value });
    } catch (error) {
      pubKey.setError(parseError(error));
    }
  };

  if (
    user?.provision_setup_status === "IN_PROGRESS" ||
    user?.provision_setup_status === "DONE"
  ) {
    return (
      <>
        <div className="block md:hidden">
          <div className="text-[12px] mb-5">
            <div className="text-object-primary text-[16px] font-bold my-4">
              Generate workspace keys
            </div>
            <div className="mb-5">
              We need some time (about 1 work day) to set up your environment.
            </div>
            <div className="mb-5">
              As soon as the setup is complete, you will receive an email.
            </div>
            <div>
              Status:{" "}
              <span className={`${status?.color} font-bold`}>
                {status?.text}
              </span>
            </div>
          </div>
          <div>
            <Link to="/dashboard">
              <Button
                type={"primary"}
                onClick={() => localStorage.removeItem("isOnboarded")}
                text="Go to account"
                className="py-2 text-[16px] text-object-primary"
                before={<></>}
                after={
                  <svg
                    width="5"
                    height="9"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 9L5 5L1 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
              />
            </Link>
          </div>
        </div>
        <div className="hidden md:block">
          <div className="text-[12px] mb-5">
            <div className="mb-5">
              We need some time (about 1 work day) to set up your environment.
            </div>
            <div className="mb-5">
              As soon as the setup is complete, you will receive an email.
            </div>
            <div>
              Status:{" "}
              <span className={`${status?.color} font-bold`}>
                {status?.text}
              </span>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div></div>
            <div>
              <Link to="/dashboard">
                <Button
                  type={"primary"}
                  onClick={() => localStorage.removeItem("isOnboarded")}
                  text="Go to account"
                  after={
                    <svg
                      width="5"
                      height="9"
                      viewBox="0 0 6 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 9L5 5L1 1"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                />
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (createdAccout) {
    return (
      <>
        <div className="block md:hidden">
          <div className="text-object-primary text-[16px] font-bold my-4">
            Generate workspace keys
          </div>
          <div className="text-[12px] text-text mb-5">
            The public key is necessary for us to set up a secure Intel SGX key
            storage.
            <br />
            <br />
            This technology allows you to create a secure enclave that only your
            Multik Provisioning application can access.
            <br />
            <br />
            Paste the public key into the input field below:
          </div>
          <div className="">
            <Input
              error={pubKey.error}
              value={pubKey.value}
              onChange={pubKey.onChange}
              type="textarea"
              placeholder="Insert your public key here."
            />
          </div>
          <div className="flex flex-col justify-between pb-5">
            <div>
              <Button
                onClick={() => setCreatedAccount(false)}
                type="outlined"
                text="Back"
                className="py-2 text-[16px] text-object-primary"
                after={<></>}
                before={
                  <svg
                    width="7"
                    height="13"
                    viewBox="0 0 5 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 1.5L1 4.5L4 7.5"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
              />
            </div>
            <div className="mt-4">
              <Button
                type="primary"
                text="Confirm"
                className="py-2 text-[16px] text-object-primary"
                onClick={onClick}
                before={<></>}
                after={
                  <svg
                    width="7"
                    height="13"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 9L5 5L1 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
              />
            </div>
          </div>
        </div>
        <div className="hidden md:block">
          <div className="w-[540px] text-[12px] mb-5">
            The public key is necessary for us to set up a secure Intel SGX key
            storage.
            <br />
            This technology allows you to create a secure enclave that only your
            Multik Provisioning application can access.
          </div>
          <div className="w-[400px]">
            <Input
              error={pubKey.error}
              value={pubKey.value}
              onChange={pubKey.onChange}
              type="textarea"
              placeholder="Insert your public key here."
            />
          </div>
          <div className="flex justify-between">
            <div>
              <Button
                onClick={() => setCreatedAccount(false)}
                type="outlined"
                text="Back"
                before={
                  <svg
                    width="5"
                    height="9"
                    viewBox="0 0 5 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 1.5L1 4.5L4 7.5"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
              />
            </div>
            <div>
              <Button
                type="primary"
                text="Confirm"
                onClick={onClick}
                after={
                  <svg
                    width="5"
                    height="9"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 9L5 5L1 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="block md:hidden">
        <div className="text-object-primary text-[16px] font-bold my-4">
          Generate workspace keys
        </div>
        <div className="text-[12px] mb-5">
          Let's set up the Provisioning app.
        </div>
        <div className="flex flex-col text-text text-[12px] mb-5">
          <div className="">1. Open Provisioning app and create an account</div>
          <div className="my-4">
            2. In the next step, insert the public key.
          </div>
          <div className="">
            3. Please wait ~24 hours while we set up your environment.
          </div>
        </div>
        <div className="flex flex-col text-[12px] mb-5">
          <div className="mr-5 mb-2">Your Multik Provisioning domain:</div>
          <Input
            className="-mb-5"
            type="text"
            onChange={() => null}
            disabled={true}
            copyable={true}
            value={user?.provision_domain}
          />
        </div>
        <div className="flex flex-col justify-between">
          <ViewInstructionsModal />
          <div className="mt-4">
            <Button
              type="primary"
              onClick={() => setCreatedAccount(true)}
              text="Account created"
              className="py-2 text-[16px] text-object-primary"
              before={<></>}
              after={
                <svg
                  width="5"
                  height="9"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 9L5 5L1 1"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
            />
          </div>
        </div>
      </div>
      <div className="hidden md:block">
        <div className="text-[12px] mb-5">
          Let's set up the Provisioning app.
        </div>
        <div className="flex items-center text-[12px] mb-5">
          <div className="mr-5">Your Multik Provisioning domain:</div>
          <Input
            className="w-[210px] -mb-5"
            type="text"
            onChange={() => null}
            disabled={true}
            copyable={true}
            value={user?.provision_domain}
          />
        </div>
        <div className="flex text-[12px] mb-5">
          <div className="w-[130px]">
            Open Provisioning app and create an account
          </div>
          <div className="mx-5 flex justify-center items-center">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.33322 8.00041L12.6666 8.00041"
                stroke="#B9BEC8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.99963 3.33373L12.6663 8.0004L7.99963 12.6671"
                stroke="#B9BEC8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="flex w-[105px]">
            In the next step, insert the public key.
          </div>
          <div className="mx-5 flex justify-center items-center">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.33322 8.00041L12.6666 8.00041"
                stroke="#B9BEC8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.99963 3.33373L12.6663 8.0004L7.99963 12.6671"
                stroke="#B9BEC8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="w-[138px]">
            Please wait ~24 hours while we set up your environment.
          </div>
        </div>
        <div className="flex justify-between">
          <ViewInstructionsModal />
          <div>
            <Button
              type="primary"
              onClick={() => setCreatedAccount(true)}
              text="Account created"
              after={
                <svg
                  width="5"
                  height="9"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 9L5 5L1 1"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
