import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { AnimatePresence, motion } from "framer-motion";
import { createPortal } from "react-dom";
import Button from "../Button";
import { useAuth } from "@/hooks/useAuth";

export default function DidntReceiveModal({ isMobile = false }: { isMobile?: boolean }) {
  const [open, setOpen] = React.useState(false);
  const { user } = useAuth();

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger>
        <div className={` ${isMobile ? "text-[14px]" : "text-[12px]"} text-accent-primary font-bold cursor-pointer`}>
          Didn’t get the code?
        </div>
        {createPortal(
          <AnimatePresence mode="popLayout">
            {open && (
              <motion.div
                className="overflow-hidden w-screen h-screen absolute top-0 left-0"
                exit={{
                  backdropFilter: "blur(5px) opacity(0)",
                }}
                initial={{
                  backdropFilter: "blur(5px) opacity(0)",
                }}
                animate={{
                  backdropFilter: "blur(5px) opacity(1)",
                }}
              ></motion.div>
            )}
          </AnimatePresence>,
          document.getElementById("blur") as HTMLElement
        )}
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className="fixed top-4 left-[50%] h-screen md:h-auto md:overflow-auto w-[calc(100%-36px)] md:w-auto md:top-[50%] md:left-[50%] -translate-x-[50%] md:-translate-y-[50%]">
          <div className="p-5 md:max-w-[330px] bg-base rounded-md border border-border text-text">
            <div>
              <div className="flex justify-between items-center mb-5">
                <div className="text-[20px] font-bold">Didn't receive the code?</div>
                <div className="cursor-pointer" onClick={() => setOpen(!open)}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 5L5 15"
                      stroke="#475673"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5 5L15 15"
                      stroke="#475673"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>

              <div className="text-[14px] mb-2">Try these steps:</div>
              <ul className="text-[14px] list-disc list-outside p-1 pl-4">
                <li className="mb-3">Check if the email is in the spam folder.</li>
                <li className="mb-3">Check if your email address is {user?.email}.</li>
                <li className="mb-3">
                  The email may have been delayed. Please retry in ten (10)
                  minutes.
                </li>
                <li className="mb-3">
                  If you've already tried all the above options and still
                  haven't received it, please contact our customer service:
                  support@multik.io
                </li>
              </ul>
              <div className="w-full h-[1px] bg-border my-5"></div>
              <div className="flex justify-between items-center">
                <div></div>
                <div>
                  <Button
                    onClick={() => setOpen(!open)}
                    className="text-white"
                    type="primary"
                    text="Confirm"
                    after={
                      <svg
                        width="5"
                        height="9"
                        viewBox="0 0 6 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 9L5 5L1 1"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
