import { useEffect, useState } from "react";
import Button from "../Button";
import Input from "../Input";
import { useOnboarding } from "@/hooks/useOnboarding";
import { useAuth } from "@/hooks/useAuth";

const paymentStatus = {
  ["VERIFICATION"]: {
    text: "Payment verification",
    color: "text-warning",
  },
  ["RECEIVED"]: {
    text: "Payment received",
    color: "text-success",
  },
  ["NOT_RECEIVED"]: {
    text: "Payment not received",
    color: "text-error",
  },
};

export default function MakePayment() {
  const { user, refetchUser } = useAuth();
  const { setTransferedPayment, setCurrentStep, setCurrentSubStep } =
    useOnboarding();
  const [chosenPlan] = useState("advanced");
  const [transfered, setTransfered] = useState(user?.payment_status !== null);
  // @ts-ignore
  const status = paymentStatus[user?.payment_status];
  const refetchOnStatusChange = async () => {
    const { data } = await refetchUser();
    setCurrentStep(data.step);
    setCurrentSubStep(0);
  };

  useEffect(() => {
    refetchUser();
  }, []);

  const onClick = () => {
    setTransferedPayment();
    setTransfered(true);
  };

  if (transfered) {
    return (
      <>
        <div className="block md:hidden">
          <div>
            <div className="text-object-primary text-[16px] font-bold my-4">
              Make payment
            </div>
          </div>
          <div>
            <div className="text-[12px] text-text mb-5">
              <div className="mb-5">Processing your payment... </div>
              <div className="mb-5">
                Status:{" "}
                <span className={`font-bold ${status?.color}`}>
                  {status?.text}
                </span>
              </div>
              {user?.payment_status === "RECEIVED" ? (
                <div>
                  We have successfully received your payment. A confirmation
                  email has been sent to your email address.
                </div>
              ) : (
                <div>
                  Once we receive and verify your payment, we will send you an
                  email confirmation.
                </div>
              )}
              <div className="mt-5">
                If you have any questions about the payment, please contact us
                via email at support@multik.io
              </div>
            </div>
            <div className="flex flex-col justify-between">
              <div>
                <Button
                  type="outlined"
                  text="Back"
                  className="py-2 text-[16px] text-object-primary"
                  onClick={() => setTransfered(false)}
                  after={<></>}
                  before={
                    <svg
                      width="7"
                      height="13"
                      viewBox="0 0 5 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 1.5L1 4.5L4 7.5"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                />
              </div>
              <div>
                <Button
                  onClick={refetchOnStatusChange}
                  type={
                    user?.payment_status === "RECEIVED" ? "primary" : "disabled"
                  }
                  text="Next step"
                  className="py-2 text-[16px] text-object-primary mt-4"
                  before={<></>}
                  after={
                    <svg
                      width="7"
                      height="13"
                      viewBox="0 0 6 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 9L5 5L1 1"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="hidden md:block">
          <div className="text-[12px] text-text mb-5">
            <div className="mb-5">Processing your payment... </div>
            <div className="mb-5">
              Status:{" "}
              <span className={`font-bold ${status?.color}`}>
                {status?.text}
              </span>
            </div>
            {user?.payment_status === "RECEIVED" ? (
              <div>
                We have successfully received your payment. A confirmation email
                has been sent to your email address.
              </div>
            ) : (
              <div>
                Once we receive and verify your payment, we will send you an
                email confirmation.
              </div>
            )}
            <div className="mt-5">
              If you have any questions about the payment, please contact us via
              email at support@multik.io
            </div>
          </div>
          <div className="flex justify-between">
            <div>
              <Button
                type="outlined"
                text="Back"
                onClick={() => setTransfered(false)}
                before={
                  <svg
                    width="5"
                    height="9"
                    viewBox="0 0 5 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 1.5L1 4.5L4 7.5"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
              />
            </div>
            <div>
              <Button
                onClick={refetchOnStatusChange}
                type={
                  user?.payment_status === "RECEIVED" ? "primary" : "disabled"
                }
                text="Next step"
                after={
                  <svg
                    width="5"
                    height="9"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 9L5 5L1 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  if (chosenPlan === "advanced") {
    return (
      <>
        <div className="block md:hidden">
          <div>
            <div className="text-object-primary text-[16px] font-bold my-4">
              Make payment
            </div>
          </div>
          <div>
            <div className="flex flex-col justify-between text-[12px] text-text mb-5">
              <div className="mb-4">
                1. Transfer 100 USDC (ERC-20) from your wallet to the address.{" "}
              </div>
              <div className="">
                2. After making the transfer, click the "PAID" button.
              </div>
            </div>
            <div className="">
              <Input
                disabled={true}
                type="textarea"
                copyable={false}
                placeholder="0xDeFFf89752BC4aE304E5c3Aa71E917ae638e47C4"
                value="0xDeFFf89752BC4aE304E5c3Aa71E917ae638e47C4"
                onChange={() => null}
              />
            </div>
            <div
              onClick={() => {
                navigator.clipboard.writeText(
                  "0xDeFFf89752BC4aE304E5c3Aa71E917ae638e47C4"
                );
              }}
              className="mb-3 -mt-1 uppercase text-[14px] font-bold text-accent-primary"
            >
              Copy address
            </div>
            <div className="text-error text-[12px] max-w-[400px] mb-5">
              Important! Send funds only on the Ethereum network (ERC-20).
              Transferring cryptocurrency on another network will result in the
              loss of your funds.{" "}
            </div>
            <Button
              type="primary"
              onClick={onClick}
              className="py-2 text-[16px] text-object-primary"
              text="Paid"
              before={<></>}
              after={
                <svg
                  width="7"
                  height="13"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 9L5 5L1 1"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
            />
          </div>
        </div>
        <div className="hidden md:block">
          <div className="flex justify-between text-[12px] text-text mb-5">
            <div className="w-[50%]">
              1. Transfer 100 USDC (ERC-20) from your wallet to the address.{" "}
            </div>
            <div className="w-[50%]">
              2. After making the transfer, click the "PAID" button.
            </div>
          </div>
          <div className="w-[340px]">
            <Input
              disabled={true}
              type="text"
              copyable={true}
              placeholder="0xDeFFf89752BC4aE304E5c3Aa71E917ae638e47C4"
              value="0xDeFFf89752BC4aE304E5c3Aa71E917ae638e47C4"
              onChange={() => null}
            />
          </div>
          <div className="text-error text-[12px] max-w-[400px] mb-5">
            Important! Send funds only on the Ethereum network (ERC-20).
            Transferring cryptocurrency on another network will result in the
            loss of your funds.{" "}
          </div>
          <div className="flex justify-between">
            <div>
              {/* <Button
              type="outlined"
              text="Back"
              before={
                <svg
                  width="5"
                  height="9"
                  viewBox="0 0 5 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 1.5L1 4.5L4 7.5"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
            /> */}
            </div>
            <div>
              <Button
                type="primary"
                onClick={onClick}
                text="Paid"
                after={
                  <svg
                    width="5"
                    height="9"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 9L5 5L1 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
