import Aside from "@/components/Aside";
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import { useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import React, { PropsWithChildren } from "react";

export default function Layout({ children }: PropsWithChildren) {
  const location = useLocation();
  return (
    <div>
      <Header />
      <div className="w-full">
        <div className="flex max-w-7xl m-auto text-text">
          <Aside />
          <div className="md:pl-36 pl-5 w-full pt-10">
            <AnimatePresence mode="popLayout">
              <motion.div
                key={location.pathname}
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 50 }}
              >
                {children &&
                  React.cloneElement(<>{children}</>, {
                    key: location.pathname,
                  })}
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
